import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, timeout, retry} from 'rxjs/internal/operators';
import {map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';

import {environment} from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';

type AgFilterInfo = {
    table: string,
    start: number,
    limit: number,
    sort: string,
    quickfilter: string,
    filterModel: {}
    filterModelJSON: string
};

@Injectable()
export class AdmingridService {

    public lastAction: string = '';
    url: string;
    protected currentFilters: AgFilterInfo;

    constructor(private http: HttpClient, private authService: AuthService) {
        this.url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
    }

    createItem(table): Observable<any> {
        this.lastAction = 'createItem';
        return this.http.get(this.urlWithTable(table) + '/create').pipe(map((response) => response), catchError(this.errorHandler));
    }

    addItem(table, item: Object): Observable<any> {
        this.lastAction = 'addItem';
        let maxTimeout = 300000
        if(table === "keycodescreation") {
          maxTimeout = 900000
        }

      return this.http.post(this.urlWithTable(table), item).pipe(
        timeout(maxTimeout),
        map((response) => response), catchError(this.errorHandler),
        retry(0)
      );
    }


    getFilters(): AgFilterInfo {
        return Object.assign({}, this.currentFilters);
    }

    getItems(table: string, start: number, limit: number, sort: {}[], quickFilterText: string, filterModel: {}): Observable<any> {
        // console.log('get items');

        this.lastAction = 'getItems';
        // console.log('getItem', quickFilterText);

        let sortQuery = this.getSortQuery(sort);
        let filterModelJSON = JSON.stringify(filterModel);

        var f = this.currentFilters;

        this.currentFilters = {
            table: table,
            start: start,
            limit: limit,
            sort: sortQuery,
            quickfilter: quickFilterText,
            filterModel: Object.assign({}, filterModel),
            filterModelJSON: filterModelJSON
        };

        const options = this.getOptions();

        return this.http.get<any>(this.urlWithTable(table), options).pipe(map((response) => response), catchError(this.errorHandler));

    }

    /**
     * Returns the 'sort' parameter value of a GET query.
     * @param sort Array of sort setting objects.
     */
    protected getSortQuery(sort: {}[]): string {
        let sortQuery: string[] = [];
        if (sort.length > 0) {
            sort.forEach((i: object, idx, array) => {
                sortQuery.push(i['colId'] + ' ' + i['sort']);
            });
        }
        return sortQuery.join(';');
    }

    protected getOptions(distinctColumnId = null, lastRowId = null): { headers: HttpHeaders; params: HttpParams; } {
        let f = this.currentFilters;

        let headers = new HttpHeaders({
            // HttpHeaders must be URI encoded for UTF-8 support.
            'AgGridFilters': encodeURIComponent(f.filterModelJSON)
        });
        let paramsObj = Object.assign(
            {}, this.currentFilters,
            {
                colid: distinctColumnId,
                lastrowid: lastRowId
            }
        );
        let params = this.getHttpParams(paramsObj);
        const options = {
            headers: headers,
            params: params
        };
        return options;
    }

    /**
     * Creates a HttpParams instance from an object. Omits empty keys.
     * @param paramsObj
     */
    protected getHttpParams(paramsObj: {}): HttpParams {
        let params = {};

        for (const key in paramsObj) {
            if (paramsObj.hasOwnProperty(key)) {
                const value = paramsObj[key];
                if (value != null && value != '')
                    params[key] = `${value}`;
            }
        }

        return new HttpParams({fromObject: params});
    }

    getItem(table, id: String): Observable<any> {
        this.lastAction = 'getItem';
        return this.http.get(this.urlWithTable(table) + '/' + id).pipe(
            map((response) => response),
            /* Http error is handled in: src/app/my-http-interceptor.ts
            catchError(err => {
                console.log(err);
                return throwError(err);
            }),
            */
        );
    }

    updateItem(table, item: Object): Observable<any> {
        this.lastAction = 'updateItem';
        const url = this.urlWithTable(table) + '/' + item['id'];
        return this.http.put(url, item).pipe(map((response) => response), catchError(this.errorHandler));
    }

    uploadFiles(table: string, item: Object, id?: string, params?: any): Observable<any> {
        this.lastAction = 'uploadFiles';
        const url = this.urlWithTable(table) + '/files/' + (id || '0');

        let options = {};
        if (params) {
            options = {
                params: params
            };
        }

        return this.http.post(url, item, options).pipe(map((response) => response), catchError(this.errorHandler));
    }

    deleteTempFiles(table: string, uid: string): Observable<any> {
        const url = this.urlWithTable(table) + '/deletetempfiles/';
        const item = {
            _file_data_uuid: uid
        };
        return this.http.post(url, item).pipe(map((response) => response), catchError(this.errorHandler));
    }

    refreshToken() {
        return this.http.post(this.url + '/refresh', {}).pipe(map(response => {
                //console.log('refreshToken response: ' + response);
                if (!response || (response && !response.hasOwnProperty('access_token'))) {
                    sessionStorage.clear();
                    window.location.href ='/login';
                    return false;
                } else {
                    const token: any = response['access_token'];
                    sessionStorage.setItem('token', token);
                    //console.log('New token: ' + token);
                    const expire_at = Date.now() + (response['expires_in'] * 1000);
                    sessionStorage.setItem('token_expire_at', expire_at.toString());
                    return true;
                }
            }), catchError(this.errorHandler)
        );
    }

    deleteItem(table: string, id: string, prop:object): Observable<any> {
        this.lastAction = 'deleteItem';
        var properties = encodeURI(JSON.stringify(prop));
        const url = this.urlWithTable(table) + '/' + id + '?prop='+properties;
        return this.http.delete(url).pipe(map((response) => response), catchError(this.errorHandler));
    }
    validateUser(prop: Object): Observable<any> {
      this.lastAction = 'validateUser';
      return this.http.post(this.url + '/validateuser', prop).pipe(map((response) => response), catchError(this.errorHandler));
    }

    maintenanceBook(table: string, id: string, prop:object): Observable<any> {
      this.lastAction = 'maintenanceBook';
      const url = this.urlWithTable(table) + '/' + id + '?action=maintenance';
      return this.http.put(url, prop).pipe(map((response) => response), catchError(this.errorHandler));
    }

    getWebglZip(table: string, id: string, prop:object): Observable<any> {
      this.lastAction = 'getWebglFiles';
      const url = this.urlWithTable(table) + '/getwebglzip/' + id;
      return this.http.get(url, {responseType: 'blob'}).pipe(map((response) => response), catchError(this.errorHandler));
    }

    getQrCodesZip(id: string): Observable<any> {
      this.lastAction = 'getQrCodesZip';
      const url = this.urlWithTable('keycodescreation') + '/getqrcodeszip/' + id + '?t=' + Date.now();
      return this.http.get(url, {responseType: 'blob'}).pipe(map((response) => response), catchError(
        (error: HttpErrorResponse) => {
          if(error.status == 404) {
            return throwError({message: 'Zip is missing!'});
          } else {
            return throwError(error.error || {message: 'Server Error'});
          }
        }
      ));
    }

    getQrLogoSvgPart(id: string): Observable<any> {
      this.lastAction = 'getQrLogoSvgPart';
      const url = this.urlWithTable('keycodescreation') + '/getqrlogosvgpart/' + id;
      return this.http.get(url).pipe(map((response) => response), catchError(this.errorHandler));
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.error || {message: 'Server Error'});
    }

    urlWithTable(table) {
        return this.url + '/' + table;
    }

    setLanguage(table, item: Object): Observable<any> {
        this.lastAction = 'setLanguage';
        return this.http.post(this.urlWithTable(table), item).pipe(map((response) => response), catchError(this.errorHandler));
    }

    getUniqueValues(colId: string, lastRowId?: any): Observable<{}[]> {
        // console.log('getUniqueValues');

        let options = this.getOptions(colId, lastRowId);
        let tableName = this.currentFilters.table;
        let result = this.http.get<{}[]>(`${this.url}/${tableName}/getUniqueValues`, options);

        result.subscribe({
            error: this.errorHandler
        });

        return result;
    }

    deleteKeycodeUsage( id: string): Observable<any> {
      this.lastAction = 'deleteKeycodeUsage';
      const url =  this.url + '/keycodes/deleteKeycodeUsage/'+ id;
      return this.http.get(url).pipe(map((response) => response), catchError(this.errorHandler));
    }

    keepBookEditConnection( id: string): Observable<any> {
      const url =  this.url + '/books/updateunderedit/'+ id;
      return this.http.get(url,{
        headers: { ignoreLoadingBar: '' }
      }).pipe(map((response) => response), catchError(this.errorHandler));
    }





}
